import styled from 'styled-components';


export const Kategorie = styled.h1`
    font-weight: bold;
    line-height: 3.0;

    @media screen and (max-width: 750px){
        line-height: 2.0;
        font-size: 22px;
    }
`;

export const Über = styled.h2`
    font-weight: bold;
    line-height: 3.0;
    @media screen and (max-width: 750px){
        line-height: 2.0;
        font-size: 18px;
    }
`;

export const Fett = styled.h3`
    font-weight: bold;
    
`;
export const Text = styled.p`
@media screen and (max-width: 750px){
    font-size: 15px;
}
    
`;

export const Textb = styled.p`
    font-weight: bold;
    line-height: 2.0;
    @media screen and (max-width: 750px){
        line-height: 2.0;
    }
`;