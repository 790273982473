import styled from 'styled-components';


export const GifRow = styled.div`
  display: flex;
  padding-top: 25vh;
  width:400px;
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 768px){
    padding-top: 12vh;
    width: 80vw;
  }
`;

export const Gif = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;


export const Wallpaper = styled.div`
  background-image: url(${require('../../images/hausDrohneW20.jpg')});
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  padding: 100px 0 50vh 0;

  /* Set rules to fill background */
  height: 90vh;
	
  /* Set up proportionate scaling */
  width: 100%;

  align-items: center;

  @media screen and (max-width: 768px){
    height: 80vh;
  }
  `;



export const IconBar = styled.div`
  position: relative;
  width: 100%;
  height: 5%;
  color: #4e5355;
  padding-left 10px;
`;

export const IconElement = styled.div`
  margin-right: 10px;

  &:hover{
    color: #df2128; 
  }

`;

export const MailLink = styled.a`
  text-decoration: none;
  color: #4e5355;

  &:hover{
    color: #df2128; 
  }

`;
