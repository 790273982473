import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button2 } from '../../globalStyles';
import 'react-lazy-load-image-component/src/effects/blur.css';
import handleViewport from 'react-in-viewport';
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img
} from './InfoSection.elements';
import { Component } from 'react';

class InfoSectionBlock extends Component{

  getStyle() {
    const { inViewport, enterCount } = this.props;
    //Fade in only the first time we enter the viewport
    if (inViewport && enterCount === 1) {
      return {         WebkitTransition: 'all 1s ease-in-out',
      transition: 'all 1s ease-in-out',
      transform: 'translateX(0)',
      opacity: 1, };
    } else if (!inViewport && enterCount < 1) {
      return { WebkitTransition: 'none', opacity: '0' };
    } else {
      return {};
    }
  }


  render() {
  return (
    <>
      <InfoSec style={this.getStyle()} lightBg={this.props.lightBg} >
        <Container>
          <InfoRow imgStart={this.props.imgStart}>
            <InfoColumn>
              <TextWrapper>
                <TopLine lightBg={this.props.lightBg}>{this.props.topLine}</TopLine>
                <Heading lightBg={this.props.lightBg}>{this.props.headline}</Heading>
                <Subtitle lightBg={this.props.lightBg}>{this.props.description}</Subtitle>
                {this.props.button && this.props.external ?
                  <a href={this.props.buttonLink}>
                    <Button2 big fontBig primary={this.props.primary}>
                      {this.props.buttonLabel}
                    </Button2>
                  </a> : ''}
                  {this.props.button && !this.props.external ?
                  <Link to={this.props.buttonLink}>
                    <Button2 big fontBig primary={this.props.primary}>
                      {this.props.buttonLabel}
                    </Button2>
                  </Link> : ''}
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper start={this.props.start}>
                <Img src={this.props.img} effect="blur" alt={this.props.alt} />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  );}
}
const InfoSection= handleViewport(InfoSectionBlock);
export default InfoSection;
