import styled from 'styled-components';
var color2 = '#de232c';
var bgcolor = '#4e5355';

export const ContactWrap = styled.div`
  align-items: center;
  width: 50%;
  padding: 50px;
  margin-bottom: 20px;

  @media screen and (max-width: 850px) {
    width: 100%;
    text-align: center;
  }
`;

export const FormGroup = styled.div`
  margin: 10px;
  margin-right: 40px;
  display: grid;
`;

export const MainFlexbox = styled.div`
  background: #fff;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */


  @media screen and (max-width: 850px) {
    display: grid;
  }
`;

export const KontaktBox = styled.div`
  width: 50%;
  padding: 50px;
  @media screen and (max-width: 850px) {
    width: 100%;
    align-items: center;
    text-align: center;
  }
`;

export const FormGroupSec = styled.div`
  display: none;
`;

export const Form = styled.form`
  margin: 0 auto;
  display: inline-grid;
  padding: 10px;
  color: ${bgcolor};
`;

export const Label = styled.label`
  padding-right: 100x;
`;
export const Adress = styled.p`
  color: ${bgcolor};
  padding: 10px;
  &:hover{
    color:${color2};
  }
`;

export const AdressEntry = styled.div`
  padding-left: 20px;
  align-items: center;
  margin: 0 auto;
`;

export const Mailto = styled.a`
  text-decoration: none;
  color: ${bgcolor};
  
  &:hover{
    color: ${color2};
    font-weight: solid;
  }
`;


export const Entry = styled.div`
  display: flexbox;
  margin: 20px;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */



  &:hover{
    color:${color2};
    border-bottom: 2px solid ${bgcolor};
    transition: 0.5s all ease-in;
  }
  @media screen and (max-width: 850px) {
    width: 100%;
    align-items: center;
    text-align: center;
  }
`;

export const Buttonbox = styled.div`
@media screen and (max-width: 850px) {
  width: 50vw;
  height: 5vh;
  margin-left: 2vw;
}
`;

export const Input = styled.input`
@media screen and (max-width: 850px) {
  width: 50vw;
  height: 5vh;
  align-items: center;
  text-align: center;
}
  
`;