import styled from 'styled-components';


var color1 = '#fff';

export const Picbox = styled.div`
    height: 100vh;
    text-align: center;
    align-items: center;
    margin-bottom:20px;

    max-width: 1250px;
    background: #FFF;
    @media screen and (max-width: 768px){
        height: 50vh;
        padding-top: 60px;
    }

    @media screen and (max-width: 400px){
        height: 60vh;
    
    }


`;

export const Pics = styled.img`
    width: inherit;
    height: inherit;
        
    max-width: 1250px;
    box-shadow: 0 0 10px 1px rgba(131, 11, 11);

    
`;

export const PicsMobile = styled.div`
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    padding-top: 75%;

    width: 100%;
    height: 0;

    align-items: center;

`;

export const Beschreibung = styled.h1`
    color: ${color1}; 
    z-index: 991;

    @media screen and (max-width: 768px){
        font-size: 20px;
    }

    @media screen and (max-width: 400px){
        font-size: 18px;
    }
`;

export const Textbox = styled.div`
    margin-left: 10px;
    position: absolute;
    width: 98%;
    max-width: 1200px;
    bottom: 10vh;
    background-color: rgba(94, 94, 94, 0.8);
    text-align: center;
    align-content: center;
    border-radius: 50px;
    box-shadow: 0 0 50px 1px #000;

    @media screen and (max-width: 768px){
        bottom: -1vh;    
        position: relative;
        width: 95%;
    }
`;