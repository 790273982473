import React, { useState, useEffect } from 'react'
import {
    Wallpaper,
    Heading,
    Slogan,
    Box
} from './Banner.elements';
function BannerSection({ img,imgMobile, header, slogan }) {


    const [mobile, setMobile] = useState(false);

    const isMobile = () => {
        if (window.innerWidth <= 550) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };
    useEffect(() => {
        isMobile();
    }, []);
    return (
        <>
            {mobile ?
                <Wallpaper style={{ backgroundImage: imgMobile }}>
                    <Box>
                        <Heading>{header}</Heading>
                        <Slogan>{slogan}</Slogan>
                    </Box>
                </Wallpaper> :
                <Wallpaper style={{ backgroundImage: img }}>
                    <Box>
                        <Heading>{header}</Heading>
                        <Slogan>{slogan}</Slogan>
                    </Box>
                </Wallpaper>}
        </>
    )
}

export default BannerSection;
