import React from 'react';
import { InfoSection } from '../../components';
import { Wrapper } from '../HomePage/Home.elements';
import { homeObjOne } from './Data';

import BannerSection from '../../components/BannerSection/BannerSection';

function Ueberuns() {
  return (
    <>
      <Wrapper>
        <BannerSection img={`url(${require('../../images/HausBramstedtUnten.jpg')})`} imgMobile={`url(${require('../../images/HausBramstedtUntenMobile.jpg')})`} header={"Unser Maschinenverleih"} slogan={""} />
        <InfoSection {...homeObjOne} />
      </Wrapper>
    </>
  );
}

export default Ueberuns;
