import React from 'react';
import axios from 'axios';
import { Wrapper } from '../HomePage/Home.elements';

import {
  FaAddressBook,
  FaPhone,
  FaFax,
  FaEnvelope
} from 'react-icons/fa';
import {
  ContactWrap,
  FormGroup,
  FormGroupSec,
  Form,
  Label,
  MainFlexbox,
  KontaktBox,
  Adress,
  Entry,
  AdressEntry,
  Mailto,
  Buttonbox,
  Input
} from './Kontakt.elements';
import { 
  Button2,
   Heading } from '../../globalStyles';

import BannerSection from '../../components/BannerSection/BannerSection';
import Maps from '../../components/Maps';

class Kontakt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      security: '',
    }
  }

  submitEmail(e) {
    e.preventDefault();
    if (this.props.security == null) {
      axios({
        method: "POST",
        url: "/send",
        data: this.state
      }).then((response) => {
        if (response.data.status === 'success') {
          alert("Nachricht gesendet.");
          this.resetForm()
        } else if (response.data.status === 'fail') {
          alert("Nachricht fehlgeschlagen!.")
        }
      })
    } else {
      console.log(this.props.security);
    }
  }
  resetForm() {
    this.setState({ name: '', email: '', subject: '', message: '', security: ''})
  }


  render() {
    return (
      <Wrapper>
        <BannerSection img={`url(${require('../../images/contact.jpg')})`} imgMobile={`url(${require('../../images/contactMobile.jpg')})`}
          header={"Ihr Kontakt zu uns"}
          slogan={"Wir sind für Sie erreichbar!"} />
        <MainFlexbox>
          <KontaktBox>
            <Heading>Ihr Weg zu uns!</Heading>
            <Entry>
              <FaAddressBook size={60} />
              <AdressEntry>
                <Adress>Bassumer Str. 28 <br></br>27211 Bassum-Bramstedt<br /></Adress></AdressEntry>
            </Entry>
            <Entry>
              <FaPhone size={60} />
              <AdressEntry>
                <Adress>04241 / 690761</Adress></AdressEntry>
            </Entry>
            <Entry>
              <FaFax size={60} />
              <AdressEntry>
                <Adress>04241 / 690763</Adress></AdressEntry>
            </Entry>
            <Entry>
              <FaEnvelope size={60} />
              <AdressEntry>
                <Adress><Mailto href="mailto:info@schwenker-bau.de">info@schwenker-bau.de</Mailto></Adress></AdressEntry>
            </Entry>
          </KontaktBox>
          <ContactWrap>
            {/* <Heading>Oder nehmen Sie direkt Kontakt mit uns auf!</Heading> */}
            {/* HIER IST DAS KONTAKTFORMULAR */}
            {/* <Form id="contact-form" onSubmit={this.submitEmail.bind(this)} method="POST">
              <FormGroup>
                <Label htmlFor="name">Name</Label>
                <Input type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  rows="2"
                  value={this.state.name}
                  onChange={this.onNameChange.bind(this)}
                  placeholder="Ihr Name (notwendig)"
                  required />
              </FormGroup>
              <FormGroup>
                <label htmlFor="email">E-Mail Adresse</label>
                <Input type="email"
                  className="form-control"
                  id="email"
                  name="sender-mail"
                  rows="2"
                  aria-describedby="emailHelp"
                  value={this.state.email}
                  onChange={this.onEmailChange.bind(this)}
                  placeholder="Ihre eMail-Adresse (notwendig)"
                  required />
              </FormGroup>
              <FormGroup>
                <label htmlFor="message">Nachricht</label>
                <textarea
                  className="form-control"
                  rows="5"
                  id="message"
                  name="message"
                  value={this.state.message}
                  onChange={this.onMessageChange.bind(this)}
                  placeholder="Ihre Nachricht (notwendig)"
                  required />
              </FormGroup>
              <FormGroupSec>
                <label htmlFor="number">Feld bitte leerlassen</label>
                <Input className="form-control"
                  name="security" rows="5" id="security" value={this.state.security} onChange={this.onSecurityChange.bind(this)} />
              </FormGroupSec>
              <Buttonbox>
                <Button2 type="submit">Abschicken</Button2>
              </Buttonbox>
            </Form> */}
          </ContactWrap>
        </MainFlexbox>
      </Wrapper>
    );
  }

  onNameChange(event) {
    this.setState({ name: event.target.value })
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value })
  }

  onSecurityChange(event) {
    this.setState({ security: event.target.value })
  }
}

export default Kontakt;
