import React from 'react';
import { InfoSection } from '../../components';
import LeistungenBannerSection from '../../components/BannerSection/LeistungenBannerSection';
import { Wrapper } from '../HomePage/Home.elements';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';

function Leistungen() {
  return (
    <>
      <Wrapper>
        <LeistungenBannerSection />
        <InfoSection {...homeObjOne} />
        <InfoSection {...homeObjTwo} />
        <InfoSection {...homeObjThree} />
        <InfoSection {...homeObjFour} />
      </Wrapper>
    </>
  );
}

export default Leistungen;
