export const homeObjOne = {
    primary: false,
    lightBg: true,
    button: true,
    topLine: '',
    headline: 'Maschinenverleih',
    description:
      'Wir verleihen Maschinen: Teleskoplader, Mini-Bagger, Rüttler, Stemmhammer und vieles mehr. Falls Ihnen nur das passende Werkzeug fehlt, helfen wir gerne aus.',
    imgStart: '',
    img: require('../../images/Maschinenverleih/Unterstürzung mit Merlo.jpg'),
    alt: 'Merlo',
    buttonLabel: 'Kontakt',
    buttonLink: '/kontakt'
    };