import 'react-slideshow-image/dist/styles.css'

import React, { useState, useEffect } from 'react';
import { Fade, Slide } from 'react-slideshow-image';
import { Pics, PicsMobile, Picbox, Beschreibung, Textbox } from './Pics.elements';


const slideImages = [
    require('../../images/Leistungen/Ref1.JPG'),
    require('../../images/Leistungen/Ref2.JPG'),
    require('../../images/Leistungen/Ref3.JPG'),
    require('../../images/Leistungen/Ref4.JPG'),
    require('../../images/Leistungen/Ref5.JPG'),
    require('../../images/Leistungen/Ref6.JPG'),
];

function LeistungenBannerSection() {
    const [loaded1, setLoaded1] = useState(false);
    const [loaded2, setLoaded2] = useState(false);
    const [loaded3, setLoaded3] = useState(false);
    const [loaded4, setLoaded4] = useState(false);
    const [loaded5, setLoaded5] = useState(false);

    const [mobile, setMobile] = useState(false);

    const isMobile = () => {
        if (window.innerWidth <= 768) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };
    useEffect(() => {
        isMobile();
    }, []);
    return (
        <><div style={mobile ? {} : { display: 'none' }}>
        <Picbox style={!(loaded1 && loaded2 & loaded3 & loaded4 & loaded5) ? {} : { display: 'none' }}>
            <PicsMobile src={slideImages[5]} effect="blur" alt="Bilderreihe lädt" />
                    <Textbox><Beschreibung>Energieeffizientes Bauen</Beschreibung></Textbox>
        </Picbox>
        <Slide style={(loaded1 && loaded2 & loaded3 & loaded4 & loaded5) ? {} : { display: 'none' }}>
            <Picbox>
                <PicsMobile style={{ 'backgroundImage': `url(${slideImages[5]})` }} />
                    <Textbox><Beschreibung>Energieeffizientes Bauen</Beschreibung></Textbox>
            </Picbox>
            <Picbox style={loaded1 ? {} : { display: 'none' }}>
                <PicsMobile style={{ 'backgroundImage': `url(${slideImages[0]})` }} onLoad={() => setLoaded1(true)} />
                    <Textbox><Beschreibung>Erdwärmekollektoren</Beschreibung></Textbox>
            </Picbox>
            <Picbox style={loaded2 ? {} : { display: 'none' }}>
                <PicsMobile style={{ 'backgroundImage': `url(${slideImages[2]})` }} onLoad={() => setLoaded2(true)} />
                    <Textbox><Beschreibung>Innenbau</Beschreibung></Textbox>
            </Picbox>
            <Picbox style={loaded3 ? {} : { display: 'none' }}>
                <PicsMobile style={{ 'backgroundImage': `url(${slideImages[3]})` }} onLoad={() => setLoaded3(true)} />
                    <Textbox><Beschreibung>Kernbohrungen</Beschreibung></Textbox>
            </Picbox>
            <Picbox style={loaded4 ? {} : { display: 'none' }}>
                <PicsMobile style={{ 'backgroundImage': `url(${slideImages[1]})` }} onLoad={() => setLoaded4(true)} />
                    <Textbox><Beschreibung>Moderne Fassadengestaltung...</Beschreibung></Textbox>
            </Picbox>
            <Picbox style={loaded5 ? {} : { display: 'none' }}>
                <PicsMobile style={{ 'backgroundImage': `url(${slideImages[4]})` }} onLoad={() => setLoaded5(true)} />
                    <Textbox><Beschreibung>...mit individuellen Kundenwünschen</Beschreibung></Textbox>
            </Picbox>
        </Slide>
        </div>
        <div style={!mobile ? {} : { display: 'none' }}>
                <Picbox style={!(loaded1 && loaded2 & loaded3 & loaded4 & loaded5) ? {} : { display: 'none' }}>
                    <Pics src={slideImages[5]} effect="blur" alt="Bilderreihe lädt" />
                    <Textbox><Beschreibung>Energieeffizientes Bauen</Beschreibung></Textbox>
                </Picbox>
            <Fade style={(loaded1 && loaded2 & loaded3 & loaded4 & loaded5) ? {} : { display: 'none' }}>
                <Picbox>
                    <Pics src={slideImages[5]} effect="blur" alt="Bilderreihe lädt" />
                    <Textbox><Beschreibung>Energieeffizientes Bauen</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded1 ? {} : { display: 'none' }}>
                    <Pics src={slideImages[0]} effect="blur" alt="Bilderreihe lädt" onLoad={() => setLoaded1(true)} />
                    <Textbox><Beschreibung>Erdwärmekollektoren</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded2 ? {} : { display: 'none' }}>
                    <Pics src={slideImages[2]} effect="blur" alt="Bilderreihe lädt" onLoad={() => setLoaded2(true)} />
                    <Textbox><Beschreibung>Innenbau</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded3 ? {} : { display: 'none' }}>
                    <Pics src={slideImages[3]} effect="blur" alt="Bilderreihe lädt" onLoad={() => setLoaded3(true)} />
                    <Textbox><Beschreibung>Kernbohrungen</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded4 ? {} : { display: 'none' }}>
                    <Pics src={slideImages[1]} effect="blur" alt="Bilderreihe lädt" onLoad={() => setLoaded4(true)} />
                    <Textbox><Beschreibung>Moderne Fassadengestaltung...</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded5 ? {} : { display: 'none' }}>
                    <Pics src={slideImages[4]} effect="blur" alt="Bilderreihe lädt" onLoad={() => setLoaded5(true)} />
                    <Textbox><Beschreibung>...mit individuellen Kundenwünschen</Beschreibung></Textbox>
                </Picbox>
            </Fade>
            </div>
        </>
    )
}

export default LeistungenBannerSection;