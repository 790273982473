import styled, { createGlobalStyle } from 'styled-components';
var bgcolor = '#4e5355';
const GlobalStyle = createGlobalStyle`
  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Arvo', sans-serif; 
 } 
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const Button = styled.button`
  border-radius: 10px;
  background: ${({ primary }) => (primary ? '#fff' : '#de232c')};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
  color: ${({ primary }) => (primary ? '#de232c' : '#fff')};
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? '#de232c' : '#fff')};
    color: ${({ primary }) => (primary ? '#fff' : '#de232c')};
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;
export const Button2 = styled.button`
  border-radius: 15px;
  background: ${({ primary }) => (primary ? '#de232c' : '#de232c')};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
  color: ${({ primary }) => (primary ? '#fff' : '#fff')};
  font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    transition: all 0.3s ease-out;
    background-color: ${({ primary }) => (primary ? '#fff' : '#4e5355')};
    color: ${({ primary }) => (primary ? '#de232c' : '#de232c')};
    text-decoration: underline;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const Heading = styled.h1`
  color: ${bgcolor};
`;

export const LoadingIcon = styled.div`
  width: 400px;
  height: 400px;
  position: absolute;
  top: 20vh;
  left: 25vw;
  background: url(${require('../src/images/LadeIcon.gif')});
`;

export default GlobalStyle;
