import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../globalStyles';
import handleViewport from 'react-in-viewport';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {
  Wrapper,
  Headline,
  CardBox,
  Card,
  CardImg,
  Description,
  Subbox,
  Buttonbox,
  Box,
  Headline1,
} from './TitelContent.elements';
import { Component } from 'react';

class TitleContentBlock extends Component {
  getStyle() {
    const { inViewport, enterCount } = this.props;
    // Fade in only the first time we enter the viewport
    if (inViewport && enterCount === 1) {
      return { transition: 'opacity 0.75s ease-in-out' };
    } else if (!inViewport && enterCount < 1) {
      return { transition: 'none', opacity: '0' };
    } else {
      return {};
    }
  }

  render() {
    return (
      <>
        <Wrapper style={this.getStyle()}>
          <Headline1>Schwenker Bau - Ihr Bauunternehmen in Bassum</Headline1>
          <Headline>
            Wir sind Ihr Ansprechpartner für schlüsselfertiges Bauen von Einfamilien- und Mehrfamilienhäusern, Innenausbau,
            Umbau und Renovierung!
          </Headline>
          <CardBox>
            <Box>
              <Card>
                <CardImg
                  alt={'Neubau Haus'}
                  effect="blur"
                  src={require('../../images/NeubauThumb.jpg')}
                />
                <Description>Neubau</Description>
              </Card>
              <Card>
                <CardImg
                  alt={'Renovierung'}
                  effect="blur"
                  src={require('../../images/UmbauThumb.jpg')}
                />
                <Description>Renovierung</Description>
              </Card>
            </Box>
            <Box>
              <Card>
                <CardImg
                  alt={'Maschinenverleih'}
                  effect="blur"
                  src={require('../../images/MaschThumb.jpg')}
                />
                <Description>Maschinenverleih</Description>
              </Card>
              <Card>
                <CardImg
                  alt={'Sanierung'}
                  effect="blur"
                  src={require('../../images/SaniThumb.JPG')}
                />
                <Description>Sanierung</Description>
              </Card>
            </Box>
          </CardBox>
          <Subbox>
            <Buttonbox>
              <Link to="/leistungen">
                <Button>Zu den Leistungen</Button>
              </Link>
            </Buttonbox>
                </Subbox>
            </Wrapper>
        </>
    )}
}

const TitleContent = handleViewport(TitleContentBlock);
export default TitleContent;

