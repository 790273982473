import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import { Container } from '../../globalStyles';

var color2 = '#de232c';
var color1 = '#fff';
var bgcolor = '#4e5355';

export const Nav = styled.nav`
  background-color: ${({ scroll }) => (scroll ?  'rgba(131,131,131,0.9)' : 'rgba(0,0,0,0.05)')};
  font-size: 15px;
  position: fixed;
  width: 90vw;
  max-width: 1250px;
  font-weight: 800;
  z-index: 9999;
  @media screen and (max-width: 1250px) {
    
    width: 100vw;
  }
  @media screen and (max-width: 960px) {
    background: ${bgcolor};
    padding-right: 0px;
  }

`;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    background: rgba(131,131,139);
    height: 60px;
  }
`;


export const NavLogo = styled.div`
  position: relative;
  display: flex;
  background-image: url(${require('../../images/LogoTrans.png')});
  background-size: contain;
  background-repeat: no-repeat;
  height: 80px;
  width: 160px;
  padding: 10px;
  margin: 1px;

  @media screen and (max-width: 960px) {
    height: 50px;
    width: 100px;
    margin-top: 5px;
  }
`;


export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 999;
    margin-top: -0.5vh;
  }
`;


export const NavMenu = styled.ul`
  display: flex;
  list-style: none;

  @media screen and (max-width: 960px) {
    
    display: ${({ click }) => (click ? 'flex' : 'none')};
    flex-direction: column;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 60px;
    left: ${({ click }) => (click ? 0 : '-100%')};
    transition: all 0.5s ease;
    background: #83838b;
    border: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;


  @media screen and (max-width: 960px) {
    width: 100%;

    &:hover {
      border: none;
    }
  }
`;

export const NavItemBtn = styled.li`

  @media screen and (max-width: 960px) {
    display: flex;
    width: 100%;
    height: 120px;
    padding-right: 10px;
  }
`;

export const NavLinks = styled(NavLink)`
  color: ${color1};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  transition: all 0.5s ease-out;
  
  &:hover {
    color: ${color2};
    background: ${color1};
    text-weight: solid;
    padding-top: 10px;
    border-bottom: 5px solid ${color2};
    transition: all 0.5s ease-out;
  }
  @media screen and (max-width: 960px) {
    text-align: center;
    width: 100%;
    display: table;
    padding-top: 25px;
    border-bottom: 1px solid ${color1};

    &:hover {
      color: ${color2};
      border: none;
      padding-top: 25px;
      background: #fff;
      transition: all 1s ease-out;
    }
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 19px 10px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;



