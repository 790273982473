import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
export const InfoSec = styled.div`
  color: #fff;
  padding: 50px 0 ;
  background: ${({ lightBg }) => (lightBg ?  '#fff' : '#4e5355')};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  height: 650px;

  @media screen and (max-width: 768px) {    
  height: 130vh;
  }


  @media screen and (max-width: 410px) {    
    height: 170vh;
    }
`;

export const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};
`;

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }
`;

export const ImgWrapper = styled.div`
  max-width: 555px;
  display: flex;
  justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
`;

export const TopLine = styled.div`
  color: ${({ lightBg }) => (lightBg ? '#4e5355' : '#fff')};
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Img = styled(LazyLoadImage)`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 400px;

  border: 1px solid #de232c;

  &:hover{
    border: 0;
    box-shadow: 0 0 5px 5px rgba(131, 11, 11);
  }

  @media screen and (max-width: 768px) {
    max-height: 300px;
  }
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  color: ${({ lightBg }) => (lightBg ? '#4e5355' : '#fff')};


  @media screen and (max-width: 768px) {
    font-size: 35px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ lightBg }) => (lightBg ? '#4e5355' : '#fff')};
`;
