import React, { useState, useEffect } from 'react';
import {
    Wallpaper,
    IconBar,
    GifRow,
    IconElement,
    MailLink,
    Gif,
} from './HomeBannerSection.elements';
import { FaEnvelope, FaPhone } from 'react-icons/fa';

function HomeBannerSection() {
    const [loading, setLoading] = useState(false);

 

    useEffect(() => {
        setTimeout(() => setLoading(true),3000);
    }, []);
   
    return (
        <>

            <Wallpaper>
                <IconBar>
                    <IconElement>
                        <FaPhone /> 04241 / 690761
                    </IconElement>
                    <IconElement>
                        <FaEnvelope /> <MailLink href="mailto:info@schwenker-bau.de">info@schwenker-bau.de</MailLink>
                    </IconElement>
                </IconBar>
                <GifRow >
                    <Gif style={loading ? {} : { display: 'none' }} src={require('../../images/animation-trans.gif')}
                        alt="Logo lädt" />
                </GifRow>
            </Wallpaper>
        </>
    )
}

export default HomeBannerSection


