import React, { Component } from 'react';
import handleViewport from 'react-in-viewport';

class MapContainer extends Component {

  getStyle() {
    const { inViewport, enterCount } = this.props;
    //Fade in only the first time we enter the viewport
    if (inViewport && enterCount === 1) {
      return { WebkitTransition: 'opacity 1s ease-in-out', marginBottom: '20px' };
    } else if (!inViewport && enterCount < 1) {
      return { WebkitTransition: 'none', opacity: '0' };
    } else {
      return {};
    }
  }
  render() {
    return (
        <div style={this.getStyle()}>
            <iframe title="Schwenker Bau Adresse"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.2305087278137!2d8.78463391622123!3d52.89014477988603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b0c54c59766653%3A0xe1070edb71cbe86e!2sSchwenker%20Bau%20GmbH!5e1!3m2!1sen!2sde!4v1607110428661!5m2!1sen!2sde" 
            width="100%" height="450" 
            frameBorder="0" 
            style={{border:0}}
            allowFullScreen="" 
            aria-hidden="false" 
            tabIndex="0"></iframe>
      </div>
    );
  }
}

const Maps = handleViewport(MapContainer);
export default Maps;
