export const homeObjOne = {
  lightBg: true,
  primary: true,
  topLine: 'Wir bauen für Sie!',
  headline: 'Neubau',
  description:
    'Sie wollen bauen? Wir sind der Schlüssel für Ihr neues Traumhaus. Auch individuelle Wünsche berücksichtigen wir stets mit voller Überzeugung.',
  
  imgStart: '',
  img: require('../../images/ReferenzHaus.jpg'),
  alt: 'Haus',
};
export const homeObjTwo = {
  lightBg: false,
  primary: false,
  topLine: 'Wir bauen für Sie!',
  headline: 'Innenausbau',
  description: 'Außen top, innen flop? Nicht mit uns. Auch im Innenbereich soll Ihr Traumhaus den höchsten Ansprüchen entsprechen.',
  imgStart: true,
  img: require('../../images/Leistungen/Innenausbau.jpg'),
  alt: 'Innen Fenster',
};
export const homeObjThree = {
  lightBg: true,
  primary: true,
  topLine: 'Wir bauen für Sie!',
  headline: 'Kernbohrungen',
  description:
    'Wir bieten Ihnen Kernbohrungen mit großen Durchmessern.',
  
  imgStart: '',
  img: require('../../images/Leistungen/Kernbohrungen.jpg'),
  alt: 'Haus',
};
export const homeObjFour= {
  lightBg: false,
  primary: false,
  topLine: 'Wir bauen für Sie!',
  headline: 'Energieeffizientes Bauen',
  description:
    'Energieeffizenz ist gut für die Umwelt und auch für Ihren Geldbeutel. Mit unserem energieeffizienten Bauen wird Ihre Heizkostenabrechnung nicht zur bösen Überraschung.',
  
  imgStart: true,
  img: require('../../images/Leistungen/Ref6.JPG'),
  alt: 'Haus',
};
