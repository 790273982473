import styled from 'styled-components';
import { keyframes } from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

var color1 = '#fff';
var bgcolor = '#4e5355';

export const Wrapper = styled.div`
background: ${bgcolor};
align-items: center;
transition: opacity 5s ease-in.out;
margin-bottom: 20px;
`;

export const Headline = styled.h1`
  font-size: 27px;
  font-weight: bold;
  color: ${color1};
  padding: 25px;


  @media screen and (max-width: 1050px) {
    font-size: 20px;
  }

  @media screen and (max-width: 700px) {
    font-size: 17px;
  }
`;
// const typing = keyframes`{
//   from { 
//     width: 0;}
//   to { 
//     width: 100%;
//     display: none;}
// }
// `;

export const Headline1 = styled.h1`
  font-size: 30px;
  padding: 25px;
  background: url(${require('../../images/mauer.jpg')});
  background-size: contain;
  color: ${({ scroll }) => (scroll ? `${bgcolor}` : `${color1}`)};
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  margin: 0 auto;
  letter-spacing: 0;
  border-right: .15em solid #de232c;

  @media screen and (max-width: 1050px) {

    animation: none;
    white-space: normal;
    font-size: 25px;
  }

  @media screen and (max-width: 700px) {
    font-size: 23px;
  }
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }

  @media screen and (max-width: 400px) {
    font-size: 13px;
  }
`;



export const CardBox = styled.div`
  display: flexbox;
  text-align: center;
  font-size: 25px;
  padding: 10px;
  flex: 0 0 auto;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */



  @media screen and (max-width: 1050px) {
    font-size: 20px;
  }

  @media screen and (max-width: 430px) {
    font-size: 17px;
    display: grid;
    }
  }
`;
export const Box = styled.div`
  display: flexbox;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */

  flex: 0 0 auto;
  margin: 0 auto;
  @media screen and (max-width: 1050px) {
    font-size: 20px;
  }

  @media screen and (max-width: 960px) {
    display: grid;
    font-size: 17px;
  }
`;


export const Card = styled.div`
  margin: 0 auto;
  padding: 25px;
  @media screen and (max-width: 1500px) {
  }
`;

export const CardImg = styled(LazyLoadImage)`
  height: 230px;
  width: 230px;
  border-radius: 50%;
  opacity: 0.7;
  font-size: 25px;

  &:hover{
      opacity: 1;
      box-shadow: 0 0 10px 1px rgba(131, 11, 11);
  }

  @media screen and (max-width: 1180px) {
    height: 200px;
    width: 200px;
  }
  @media screen and (max-width: 1025px) {
    height: 160px;
    width: 160px;
  }

`;

export const Description = styled.div`
  color: ${color1};
`;


export const Subbox = styled.div`
  text-align: right;
  padding: 30px;
`;

export const Buttonbox = styled.p`
  margin: 0 auto;
`;
