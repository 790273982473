import React from 'react';
import GlobalStyle from './globalStyles';
import Home from './pages/HomePage/Home';
import Leistungen from './pages/Leistungen/Leistungen';
import Referenzen from './pages/Referenzen/Referenzen';
import Ueberuns from './pages/Ueberuns/Ueberuns';
import Kontakt from './pages/Kontakt/Kontakt';
import Maschinenverleih from './pages/Maschinenverleih/Maschinenverleih';
import Impressum from './pages/Rechtliches/Impressum';
import Datenschutz from './pages/Rechtliches/Datenschutz';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import { Navbar, Footer } from './components';
import { Background } from './App.elements';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import { Component } from 'react';
// import HomePic from './images/hausDrohneW20.jpg';
// import HomePicMobile from './images/hausDrohneW20Mobile.jpg';
// import RefPic from './images/Referenzen/Ref1.JPG';
// import RefPicMobile from './images/Referenzen/Ref1Mobile.JPG';
// import LeiPic from './images/Leistungen/Ref6.JPG';
// import LeiPicMobile from './images/Leistungen/Ref6Mobile.JPG';
// import ContactPic from './images/contact.jpg';
// import ContactPicMobile from './images/contactMobile.jpg';
// import DatenPic from './images/datenschutz.jpg';
// import DatenPicMobile from './images/datenschutzMobile.jpg';
// import CookiesPic from './images/Cookies.jpg';
// import CookiesPicMobile from './images/CookiesMobile.jpg';
// import ImpressumPic from './images/impressum.jpg';
// import ImpressumPicMobile from './images/impressumMobile.jpg';
// import UeberUnsPic from './images/HausBramstedtDrohne.jpg';
// import UeberUnsPicMobile from './images/HausBramstedtDrohneMobile.jpg';
// import MaschPic from './images/HausBramstedtUnten.jpg';
// import MaschPicMobile from './images/HausBramstedtUntenMobile.jpg';



// const firstImages = [
//   HomePic,
//   RefPic,
//   LeiPic,
//   ContactPic,
//   DatenPic,
//   CookiesPic,
//   ImpressumPic,
//   UeberUnsPic,
//   MaschPic
// ]

// const firstImagesMobile = [
//   HomePicMobile,
//   RefPicMobile, 
//   LeiPicMobile,
//   ContactPicMobile,
//   DatenPicMobile,
//   CookiesPicMobile,
//   ImpressumPicMobile,
//   UeberUnsPicMobile,
//   MaschPicMobile
// ]

class App extends Component {
  // componentDidMount(){
  //     const imagestoBePreloaded = (window.innerWidth <= 600)?firstImagesMobile:firstImages;
  //     imagestoBePreloaded.forEach(image => { new Image().src = image});
  // }
  render() {
    return (
      <Background>
        <Router>
          <GlobalStyle />
          <ScrollToTop />
          <Navbar />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/home' exact component={Home} />
            <Route path='/Leistungen' component={Leistungen} />
            <Route path='/Referenzen' component={Referenzen} />
            <Route path='/Ueberuns' component={Ueberuns} />
            <Route path='/Kontakt' component={Kontakt} />
            <Route path='/Impressum' component={Impressum} />
            <Route path='/Datenschutz' component={Datenschutz} />
            <Route path='/Maschinenverleih' component={Maschinenverleih} />
          </Switch>
          <Footer />
        </Router>
      </Background>
    );
  }
}


export default App;
