export const homeObjOne = {
  primary: false,
  lightBg: true,
  button: true,
  external: true,
  topLine: 'Arbeitsschutzmanagmentsystem',
  headline: 'AMS-Bau Unternehmen',
  description:
    'Arbeitsicherheit und Gesundheit stehen für uns an hoher Stelle. Das Zertifikat als AMS-Bau begutachtetes Unternehmen zeugt von unseren Prioritäten. Sicherheit und Qualität gehen Hand in Hand.',
  buttonLabel: 'Mehr zu AMS-Bau',
  imgStart: '',
  img: require('../../images/LogoAMS.jpg'),
  alt: 'AMS-Bau Logo',
  buttonLink: 'https://www.bgbau.de/service/angebote/ams-bau-arbeitsschutzmanagementsystem/was-ist-ams-bau/',
};

export const homeObjTwo = {
  primary: true,
  lightBg: false,
  button: true,
  headline: 'Unsere Referenzen',
  description:
    "Ob Neubau, Umbau, Renovierung oder Innenausbau. Wir sind Ihr Partner für Ihre Bauvorhaben. Überzeugen Sie sich selbst von unseren Projekten.",
  buttonLabel: 'Referenzen',
  buttonLink: '/referenzen',
  imgStart: 'true',
  img: require('../../images/ReferenzHaus.jpg'),
  alt: 'Haus',
};

export const homeObjThree = {
  primary: false,
  lightBg: true,
  button: true,
  external: true,

  headline: 'Jobangebot',
  description:
    "Karriere auf dem Bau? \nWir sind ständig auf der Suche nach fleißigen Maurern,Bauhelfern und Co., die mit anpacken wollen. Schicken Sie gleich Ihre Bewerbung mit Lebenslauf und Anschreiben an:",
  buttonLabel: 'Bewerbung',
  buttonLink: 'mailto:karriere@schwenker-bau.de',
  imgStart: '',
  img: require('../../images/arbeiter.jpg'),
  alt: 'Haus',
};

export const homeObjFour = {
  primary: true,
  lightBg: false,
  button: true,

  headline: 'Maschinenverleih',
  description:
    "Alles was Sie noch für die Umsetzung Ihres Bauvorhabens brauchen, ist das passende Werkzeug? Kein Problem: Bei uns können Sie Maschinen und Spezialwerkzeuge aller Art leihen.",
  buttonLabel: 'Maschinenverleih',
  buttonLink: '/maschinenverleih',
  imgStart: true,
  img: require('../../images/Maschinenverleih/Unterstürzung mit Merlo.jpg'),
  alt: 'Haus',
};
