import React from 'react';
import {
  FaFacebook,
  FaGoogle,
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterLinksContainer,
  FooterLinkExtern,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink
} from './Footer.elements';
import { Wrapper } from '../../pages/HomePage/Home.elements';

function Footer() {
  return (
    <Wrapper>
    <FooterContainer>
      <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Informationen</FooterLinkTitle>
            <FooterLink to='/referenzen'>Referenzen</FooterLink>
            <FooterLink to='/leistungen'>Leistungen</FooterLink>
            <FooterLink to='/ueberuns'>Über Uns </FooterLink>
          </FooterLinkItems>
          
        </FooterLinksWrapper>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Rechtliches</FooterLinkTitle>
            <FooterLink to='/Impressum'>Impressum</FooterLink>
            <FooterLink to='/Datenschutz'>Datenschutzerklärung</FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Links</FooterLinkTitle>
            <FooterLinkExtern href='https://de-de.facebook.com/schwenkerBauGmbH' target='_blank'>Facebook</FooterLinkExtern>
            <FooterLinkExtern href='https://www.google.com/maps/place/Schwenker+Bau+GmbH/@52.8901448,8.7868226,15z/data=!4m5!3m4!1s0x0:0xe1070edb71cbe86e!8m2!3d52.8901448!4d8.7868226'>Google</FooterLinkExtern>
          </FooterLinkItems>
        </FooterLinksWrapper>
      </FooterLinksContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to='/home'>
          <img src={require('../../images/LogoTrans.png')} width='100px' height='60px' alt='Logo' />
            Schwenker Bau
          </SocialLogo>
          <WebsiteRights> Schwenker Bau GmbH © 2020</WebsiteRights>
          <SocialIcons>
            <SocialIconLink href='https://de-de.facebook.com/schwenkerBauGmbH/' target='_blank' aria-label='Facebook'>
              <FaFacebook />
            </SocialIconLink>            
            <SocialIconLink 
            href='https://www.google.com/maps/place/Schwenker+Bau+GmbH/@52.8901448,8.7868226,15z/data=!4m5!3m4!1s0x0:0xe1070edb71cbe86e!8m2!3d52.8901448!4d8.7868226' 
            target='_blank' 
            aria-label='Google'>
              <FaGoogle />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
    </Wrapper>
  );
}

export default Footer;
