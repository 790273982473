import React from 'react'
import { Wrapper } from '../HomePage/Home.elements'
import { 
    Content,
    Developer,
    DeveloperHeader,
    Heading,
    MailLink,
    WrapperImp,
 } from './Impressum.elements'
 import BannerSection from '../../components/BannerSection/BannerSection'

function Impressum() {
    return (
        <>
        <Wrapper>
            <BannerSection img={`url(${require('../../images/impressum.jpg')})`} imgMobile={`url(${require('../../images/impressumMobile.jpg')})`} header={"Impressum"} slogan={"Was muss, das muss"}/>

            <WrapperImp>
                <h1>Impressum</h1>
                <br />
                <Heading>Herausgeber</Heading>
                <Content>Schwenker Bau GmbH <br />
                Geschäftsführer: Jörg Schwenker<br />
                Bassumer Str. 28<br />
                27211 Bassum-Bramstedt<br />
                <br /><br />
                Telefon: 04241 / 690761<br />
                Telefax: 04241 / 690763<br />
                <br /><br />
                Steuer-Nr.: 46 208 11903<br />
                Amtsgericht Walsrode: HRB 111221<br />
                <br /><br />
                E-Mail: <MailLink href='mailto:info@schwenker-bau.de'>info@schwenker-bau.de</MailLink> </Content>
                <DeveloperHeader>Programmierung:</DeveloperHeader>
                <Developer href='mailto:webmaster@schwenker-bau.de'>Fabian Seelert</Developer>
            </WrapperImp>
            </Wrapper>
        </>
    )
}

export default Impressum
