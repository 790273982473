export const homeObjOne = {
  lightBg: true,
  primary: false,
  button: false,
  topLine: 'Wir stellen uns vor:',
  headline: 'Jörg Schwenker (Geschäftsführer)',
  description:
    'Hallo, ich bin Jörg Schwenker - der Geschäftsführer und Inhaber der Firma Schwenker Bau GmbH, auf deren Website Sie sich gerade befinden. Ich bin gelernter Elektriker aus Bassum und bin nun fast 40 Jahre auf Baustellen unterwegs. Zur Geburt meiner ersten Tochter 1992 habe ich den Entschluss gefasst mich beruflich zu verändern und mich selbstständig zu machen. Im Jahre 2000 dann war es soweit: Gemeinsam mit meinen drei Brüdern gründeten wir Schwenker Bau GmbH.',
  imgStart: '',
  img: require('../../images/Jörg1.jpg'),
  alt: 'ueberuns'
};

export const homeObjTwo = {
  lightBg: false,
  primary: true,
  button: false,
  topLine: 'Wir stellen uns vor:',
  headline: 'Das Unternehmen Schwenker Bau GmbH',
  description: 'Heute bin ich alleiniger Inhaber der Firma und leite zusammen mit meiner Frau Gaby das Familienunternehmen. Wir blicken inzwischen auf nun mehr 20 Jahre Erfahrung zurück, in denen wir Kunden durch kompetente Beratung, Lösungsorientiertheit und Expertise überzeugen konnten. Dabei können wir sowohl zu Neubauten und Sanierungen, als auch zu Renovierungsarbeiten im Sinne der Energieeffizienz beraten.',
  imgStart: true,
  img: require('../../images/Jörg2.jpg'),
  alt: 'ueberuns'
};

export const homeObjThree = {
  lightBg: true,
  primary: false,
  button: false,
  topLine: 'Wir stellen uns vor:',
  headline: 'Schwenker Bau heute',
  description: 'Gemeinsam mit meiner Frau leben wir in Bassum-Bramstedt auf dem ehemaligen Vogelhof. Hier befindet sich auch der Sitz der Firma. Wir haben diesen alten Bauernhof nach unseren Vorstellungen umgebaut und gemeinsam mit unserem Sohn und unseren beiden Töchtern arbeiten wir Stück für Stück daran unser wunderbares Reich weiter zu verschönern. Inzwischen haben wir hier genügend Platz für Baumaterialien und diverse Maschinen einerseits, und private Entfaltung und Erholung auf der anderen Seite.',
  imgStart: '',
  img: require('../../images/Jörg3.jpg'),
  alt: 'ueberuns'
};

export const homeObjFour = {
  lightBg: false,
  primary: true,
  button: false,
  topLine: '',
  headline: 'Und darum Schwenker Bau',
  description: 'Wir wissen also genau worauf es bei (Um-)Baumaßnahmen ankommt - bis ins kleinste Detail! Dies zeigt sich auf unserem Hof: Wir haben es geschafft die alten Elemente des Bauernhauses mit einer modernen energieeffizienten Ausstattung zu kombinieren. Das Wohnhaus ist dabei mit Hybrid-Photovoltaikelementen ausgestattet und wird mit einer Erdwärmepumpe regenerativ versorgt - ein autarkes Heizungskonzept! Kommen Sie gerne zum Kundengespräch vorbei und überzeugen Sie sich. Wir stehen Ihnen gerne beratend zur Seite.',
  imgStart: true,
  img: require('../../images/HausBramstedtCard.jpg'),
  alt: 'ueberuns'
};
