import React from 'react';
import { InfoSection } from '../../components';
import { Wrapper } from '../HomePage/Home.elements';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';

import BannerSection from '../../components/BannerSection/BannerSection';

function Ueberuns() {
  return (
    <>
      <Wrapper>
        <BannerSection img={`url(${require('../../images/HausBramstedtDrohne.jpg')})`} imgMobile={`url(${require('../../images/HausBramstedtDrohneMobile.jpg')})`}
        header={"Das ist Schwenker Bau"} slogan={""} />

        <InfoSection {...homeObjOne} />
        <InfoSection {...homeObjTwo} />
        <InfoSection {...homeObjThree} />
        <InfoSection {...homeObjFour} />
      </Wrapper>
    </>
  );
}

export default Ueberuns;
