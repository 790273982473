import styled from 'styled-components';


export const WrapperImp = styled.div`
background-color: rgba(111,113,113,0.7);
align-items: center;
text-align: left;
padding: 100px 100px 100px 100px;
line-height: 1.6;

`;

export const Heading = styled.h1`
  color: #de232c;
  font-size: 24px;
`;

export const Content = styled.p`
  color: #fff;
`;
export const MailLink = styled.a`
  color: #de232c;
`;
export const DeveloperHeader = styled.h1`
  color: #000000;
  font-weight: bold;
  margin-top: 100px;
  font-size:20px;
`;

export const Developer = styled.a`
  color: #de232c;
  font-size:16px;
  text-decoration: none;
`;