import React from 'react';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import { InfoSection } from '../../components';
import TitleContent from '../../components/TitleContent/TitleContent';
import HomeBannerSection from '../../components/BannerSection/HomeBannerSection';
import { Wrapper } from './Home.elements';
import Maps from '../../components/Maps';
function Home() {
  return (
    <>
      <Wrapper>
      <HomeBannerSection />
      <TitleContent />
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjTwo} />
      <InfoSection {...homeObjThree} />
      <InfoSection {...homeObjFour} />
      
      </Wrapper>
    </>
  );
}

export default Home;
