import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { Button } from '../../globalStyles';
import { Wrapper } from '../../pages/HomePage/Home.elements';
import {
  Nav,
  NavbarContainer,
  MobileIcon,
  NavMenu,
  NavItem,
  NavItemBtn,
  NavBtnLink,
  NavLinks,
  NavLogo,
} from './Navbar.elements';

function Navbar() {


  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [scroll, setScroll] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const listenScrollEvent = () => {
    if (window.scrollY > 50) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    listenScrollEvent();
  }, []);
  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);
  window.addEventListener('scroll', listenScrollEvent);

  return (
    <>
      <Wrapper>
        <IconContext.Provider value={{ color: '#fff' }}>
          <Nav scroll={scroll}>
            <NavbarContainer>
              <Link to='/' >
              <NavLogo onClick={closeMobileMenu} /></Link>
              <MobileIcon onClick={handleClick}>
                {click ? <FaTimes /> : <FaBars />}
              </MobileIcon>

              <NavMenu onClick={handleClick} click={click}>
                <NavItem>
                  {!button ? <NavLinks to='/home'
                    activeStyle={{
                      color: '#de232c',
                      background: '#fff',
                    }}
                    onClick={closeMobileMenu}>
                    Home
                </NavLinks> : <NavLinks to='/home'
                      activeStyle={{
                        color: '#de232c',
                        background: '#fff',
                        paddingTop: '10px',
                        borderBottom: '5px solid #de232c',
                      }}
                      onClick={closeMobileMenu}>
                      Home
                  </NavLinks>}
                </NavItem>
                <NavItem>
                  {!button ? <NavLinks to='/leistungen'
                    activeStyle={{
                      color: '#de232c',
                      background: '#fff',
                    }}
                    onClick={closeMobileMenu}>
                    Leistungen
                </NavLinks> : <NavLinks to='/leistungen'
                      activeStyle={{
                        color: '#de232c',
                        background: '#fff',
                        paddingTop: '10px',
                        borderBottom: '5px solid #de232c',
                      }}
                      onClick={closeMobileMenu}>
                      Leistungen
                  </NavLinks>}
                </NavItem>
                <NavItem>
                  {!button ? <NavLinks to='/referenzen'
                    activeStyle={{
                      color: '#de232c',
                      background: '#fff',
                    }}
                    onClick={closeMobileMenu}>
                    Referenzen
                </NavLinks> : <NavLinks to='/referenzen'
                      activeStyle={{
                        color: '#de232c',
                        background: '#fff',
                        paddingTop: '10px',
                        borderBottom: '5px solid #de232c',
                      }}
                      onClick={closeMobileMenu}>
                      Referenzen
                  </NavLinks>}
                </NavItem>
                <NavItem>
                  {!button ? <NavLinks to='/ueberuns'
                    activeStyle={{
                      color: '#de232c',
                      background: '#fff',
                    }}
                    onClick={closeMobileMenu}>
                    Über Uns
                </NavLinks> : <NavLinks to='/ueberuns'
                      activeStyle={{
                        color: '#de232c',
                        background: '#fff',
                        paddingTop: '10px',
                        borderBottom: '5px solid #de232c',
                      }}
                      onClick={closeMobileMenu}>
                      Über Uns
                  </NavLinks>}
                </NavItem>
                <NavItem>
                  {!button ? <NavLinks to='/Maschinenverleih'
                    activeStyle={{
                      color: '#de232c',
                      background: '#fff',
                    }}
                    onClick={closeMobileMenu}>
                    Maschinenverleih
                </NavLinks> : <NavLinks to='/Maschinenverleih'
                      activeStyle={{
                        color: '#de232c',
                        background: '#fff',
                        paddingTop: '10px',
                        borderBottom: '5px solid #de232c',
                      }}
                      onClick={closeMobileMenu}>
                      Maschinenverleih
                  </NavLinks>}
                </NavItem>
                
                <NavItemBtn>
                  {button ? (
                    <NavBtnLink to='/Kontakt'>
                      <Button primary={scroll}>Kontakt</Button>
                    </NavBtnLink>
                  ) : (
                      <NavBtnLink to='/Kontakt'>
                        <Button onClick={closeMobileMenu} fontBig>
                          Kontakt
                    </Button>
                      </NavBtnLink>
                    )}
                </NavItemBtn>
              </NavMenu>

            </NavbarContainer>
          </Nav>
        </IconContext.Provider>
      </Wrapper>
    </>
  );
}

export default Navbar;
