import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 90vw;
  max-width: 1250px;
  margin: auto;
  box-shadow: 0 0 50px 1px #000;

  @media screen and (max-width: 1250px) {
    width: 100vw;
  }


`;

