import React from 'react';
import { InfoSection } from '../../components';
import ReferenzBannerSection from '../../components/BannerSection/ReferenzBannerSection';
import { Wrapper } from '../HomePage/Home.elements';
import { ReferenzObjOne } from './Data';

function Referenzen() {
  return (
    <>
      <Wrapper>
        <ReferenzBannerSection />
        <InfoSection {...ReferenzObjOne} />
      </Wrapper>
    </>
  );
}

export default Referenzen;
