import styled from 'styled-components';
import { FaMagento } from 'react-icons/fa';
import { Link } from 'react-router-dom';


export const FooterContainer = styled.div`
  background-image: url(${require('../../images/mauerDunkel.jpg')});
  background-size: contain;
  padding: 4rem 0 3rem 0;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 850px) {
    background-size: cover;
  }
`;


export const FooterLinksContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;

  &:hover{
    transition: all 1s ease;
  }

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 24px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
  color: #fff;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h2`
  margin-bottom: 16px;
  border-bottom: 1px solid #fff;    
`;

export const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;

  &:hover {
    font-weight: bold;
    color: #de232c;
    transition: 0.5s ease;
  }
`;
export const FooterLinkExtern = styled.a`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;

  &:hover {
    color: #de232c;
    transition: 0.3s ease-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
  
  margin: 0 auto;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  text-align: center;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

`;

export const SocialIcon = styled(FaMagento)`
  margin-right: 10px;
  &:hover {
    color: #de232c;
    transition: 0.3s ease-out;
  }
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
`;

export const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  width: 240px;
  margin-bottom: 10px;
  &:hover {
    color: #de232c;
    transition: 0.3s ease-out;
  }

  @media screen and (max-width: 768px){
    justify-content: space-between;
    
  }
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
  padding: 10px;
  &:hover {
    color: #de232c;
    transition: 0.3s ease-out;
  }
`;
