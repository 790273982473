import styled from 'styled-components';

var color1 = '#fff';


export const Wallpaper = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 100px;
  width: 90vw;
  height: 80vh;
  text-align: center;
  align-items: center;

  /* Set rules to fill background */
  min-height: 80vh;
	
  /* Set up proportionate scaling */
  width: 100%;
  height: auto; 

  margin-bottom: 20px;
  
`;

export const Box = styled.div`
  margin-top: 20vh;
  background: rgba(131,131,139,0.5);
  text-align: center;
`;
export const Heading = styled.h1`
  font-size: 72px;
  margin: 0 auto;
  color: ${color1};
  @media screen and (max-width: 1050px) {
    font-size: 52px;
  }
  @media screen and (max-width: 800px) {
    font-size: 32px;
  }

  @media screen and (max-width: 460px) {
    font-size: 27px;
  }
`;
export const Slogan = styled.h2`
  font-size: 30;
  margin: 0 auto;
  color: #de232c;
`;


