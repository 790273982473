import React, { useState, useEffect } from 'react';
import { Fade, Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Pics, PicsMobile, Picbox, Beschreibung, Textbox } from './Pics.elements';


const slideImages = [
    require('../../images/Referenzen/Ref1.JPG'),
    require('../../images/Referenzen/Ref2.JPG'),
    require('../../images/Referenzen/Ref3.JPG'),
    require('../../images/Referenzen/Ref4.JPG'),
    require('../../images/Referenzen/Ref5.JPG'),
    require('../../images/Referenzen/Ref6.JPG'),
    require('../../images/Referenzen/Ref7.JPG'),
    require('../../images/Referenzen/Ref8.JPG'),
    require('../../images/Referenzen/Ref9.JPG'),
];

function ReferenzBannerSection() {
    const [loaded1, setLoaded1] = useState(false);
    const [loaded2, setLoaded2] = useState(false);
    const [loaded3, setLoaded3] = useState(false);
    const [loaded4, setLoaded4] = useState(false);
    const [loaded5, setLoaded5] = useState(false);
    const [loaded6, setLoaded6] = useState(false);
    const [loaded7, setLoaded7] = useState(false);
    const [loaded8, setLoaded8] = useState(false);


    const [mobile, setMobile] = useState(false);

    const isMobile = () => {
        if (window.innerWidth <= 768) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };
    useEffect(() => {
        isMobile();
    }, []);


    return (
        <>
        <div style={mobile ? {} : { display: 'none' }}>
            <Picbox style={!(loaded1 && loaded2 & loaded3 & loaded4 & loaded5 & loaded6 & loaded7 & loaded8) ? {} : { display: 'none' }}>
                <PicsMobile src={slideImages[3]} effect="blur" alt="Bilderreihe lädt" />
                <Textbox><Beschreibung>Schlüsselfertiger Neubau in Bramstedt...</Beschreibung></Textbox>
            </Picbox>
            <Slide style={(loaded1 && loaded2 & loaded3 & loaded4 & loaded5 & loaded6 & loaded7 & loaded8) ? {} : { display: 'none' }}>
                <Picbox>
                    <PicsMobile style={{ 'backgroundImage': `url(${slideImages[3]})` }} />
                    <Textbox><Beschreibung>Schlüsselfertiger Neubau in Bramstedt...</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded1 ? {} : { display: 'none' }}>
                    <PicsMobile style={{ 'backgroundImage': `url(${slideImages[4]})` }} onLoad={() => setLoaded1(true)} />
                    <Textbox><Beschreibung>...inklusive Hofgestaltung</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded2 ? {} : { display: 'none' }}>
                    <PicsMobile style={{ 'backgroundImage': `url(${slideImages[0]})` }} onLoad={() => setLoaded2(true)} />
                    <Textbox><Beschreibung>Verblendarbeiten in Varel</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded3 ? {} : { display: 'none' }}>
                    <PicsMobile style={{ 'backgroundImage': `url(${slideImages[1]})` }} onLoad={() => setLoaded3(true)} />
                    <Textbox><Beschreibung>Einfamilienhaus in Verden</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded4 ? {} : { display: 'none' }}>
                    <PicsMobile style={{ 'backgroundImage': `url(${slideImages[8]})` }} onLoad={() => setLoaded4(true)} />
                    <Textbox><Beschreibung>Moderne Bodengestaltung</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded5 ? {} : { display: 'none' }}>
                    <PicsMobile style={{ 'backgroundImage': `url(${slideImages[2]})` }} onLoad={() => setLoaded5(true)} />
                    <Textbox><Beschreibung>Schlüsselfertiger Neubau in Bassum</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded6 ? {} : { display: 'none' }}>
                    <PicsMobile style={{ 'backgroundImage': `url(${slideImages[5]})` }} onLoad={() => setLoaded6(true)} />
                    <Textbox><Beschreibung>Verblendarbeiten in Varel</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded7 ? {} : { display: 'none' }}>
                    <PicsMobile style={{ 'backgroundImage': `url(${slideImages[6]})` }} onLoad={() => setLoaded7(true)} />
                    <Textbox><Beschreibung>Neubau einer Mosterei</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded8 ? {} : { display: 'none' }}>
                    <PicsMobile style={{ 'backgroundImage': `url(${slideImages[7]})` }} onLoad={() => setLoaded8(true)} />
                    <Textbox><Beschreibung>Individuelle Fassadengestaltung</Beschreibung></Textbox>
                </Picbox>
            </Slide>
            </div>
            <div style={!mobile ? {} : { display: 'none' }}>
            <Picbox style={!(loaded1 && loaded2 & loaded3 & loaded4 & loaded5 & loaded6 & loaded7 & loaded8) ? {} : { display: 'none' }}>
                <Pics src={slideImages[3]} effect="blur" alt="Bilderreihe lädt" />
                <Textbox><Beschreibung>Schlüsselfertiger Neubau in Bramstedt...</Beschreibung></Textbox>
            </Picbox>
            <Fade style={(loaded1 && loaded2 & loaded3 & loaded4 & loaded5 & loaded6 & loaded7 & loaded8) ? {} : { display: 'none' }}>
                <Picbox>
                    <Pics src={slideImages[3]} effect="blur" alt="Bilderreihe lädt" />
                    <Textbox><Beschreibung>Schlüsselfertiger Neubau in Bramstedt...</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded1 ? {} : { display: 'none' }}>
                    <Pics src={slideImages[4]} effect="blur" alt="Bilderreihe lädt" onLoad={() => setLoaded1(true)} />
                    <Textbox><Beschreibung>...inklusive Hofgestaltung</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded2 ? {} : { display: 'none' }}>
                    <Pics src={slideImages[0]} effect="blur" alt="Bilderreihe lädt" onLoad={() => setLoaded2(true)} />
                    <Textbox><Beschreibung>Verblendarbeiten in Varel</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded3 ? {} : { display: 'none' }}>
                    <Pics src={slideImages[1]} effect="blur" alt="Bilderreihe lädt" onLoad={() => setLoaded3(true)} />
                    <Textbox><Beschreibung>Einfamilienhaus in Verden</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded4 ? {} : { display: 'none' }}>
                    <Pics src={slideImages[8]} effect="blur" alt="Bilderreihe lädt" onLoad={() => setLoaded4(true)} />
                    <Textbox><Beschreibung>Moderne Bodengestaltung</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded5 ? {} : { display: 'none' }}>
                    <Pics src={slideImages[2]} effect="blur" alt="Bilderreihe lädt" onLoad={() => setLoaded5(true)} />
                    <Textbox><Beschreibung>Schlüsselfertiger Neubau in Bassum</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded6 ? {} : { display: 'none' }}>
                    <Pics src={slideImages[5]} effect="blur" alt="Bilderreihe lädt" onLoad={() => setLoaded6(true)} />
                    <Textbox><Beschreibung>Verblendarbeiten in Varel</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded7 ? {} : { display: 'none' }}>
                    <Pics src={slideImages[6]} effect="blur" alt="Bilderreihe lädt" onLoad={() => setLoaded7(true)} />
                    <Textbox><Beschreibung>Neubau einer Mosterei</Beschreibung></Textbox>
                </Picbox>
                <Picbox style={loaded8 ? {} : { display: 'none' }}>
                    <Pics src={slideImages[7]} effect="blur" alt="Bilderreihe lädt" onLoad={() => setLoaded8(true)} />
                    <Textbox><Beschreibung>Individuelle Fassadengestaltung</Beschreibung></Textbox>
                </Picbox>
            </Fade>
            </div>
        </>
    )
}

export default ReferenzBannerSection;