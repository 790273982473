export const ReferenzObjOne = {
  primary: false,
  lightBg: true,
  button: true,
  topLine: 'Arbeitsschutzmanagmentsystem',
  headline: 'AMS-Bau',
  description:
    'Arbeitsicherheit und Gesundheit stehen für uns an hoher Stelle. Das Zertifikat als AMS-Bau begutachtetes Unternehmen zeugen von unseren Prioritäten. Sicherheit und Qualität gehen Hand in Hand.',
  buttonLabel: 'Mehr zu AMS-Bau',
  imgStart: '',
  img: require('../../images/LogoAMS.jpg'),
  alt: 'AMS-Bau Logo',
  buttonLink: 'https://www.bgbau.de/service/angebote/ams-bau-arbeitsschutzmanagementsystem/was-ist-ams-bau/',
};